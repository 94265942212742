import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from './firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import './LoginPage.css';
import Navbar from './components/Navbar'; // Import the Navbar component

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        navigate('/chart');
      } else {
        setMessage('Please verify your email address before logging in.');
        await auth.signOut();
      }
    } catch (error) {
      setError('Error signing in: ' + error.message); // Display the error message
      console.error('Error signing in:', error);
    }
  };

  return (
    <div className="login-page">
      <Navbar /> {/* Use the Navbar component */}

      <div className="login-container">
        <div className="login-box">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button type="submit">Login</button>
          </form>
          {message && <p className="message">{message}</p>}
          {error && <p className="error">{error}</p>}
          <p>
            <Link to="/sign-up">Don't have an account? Sign up here</Link>
          </p>
          <p>
            <Link to="/reset-password">Forgot Password?</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
