// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import ProductPage from './ProductPage';
import ContactPage from './ContactPage';
import PrivacyNoticePage from './PrivacyNoticePage';
import LegalDisclaimerPage from './LegalDisclaimerPage';
import LoginPage from './LoginPage';
import SignUpPage from './SignUpPage';
import Dashboard from './Dashboard';
import ResetPassword from './components/ResetPassword';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import { initializeAnalytics, logPageView } from './analytics';

const App = () => {
  useEffect(() => {
    initializeAnalytics();
  }, []);

  return (
    <AuthProvider>
      <Router>
        <PageTracking />
        <Routes>
          {/* Define all the routes */}
          <Route path="/" element={<LandingPage />} />
  
          {/* Product page with dynamic ticker */}
          <Route path="/chart/" element={<ProductPage />} />

          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy-notice" element={<PrivacyNoticePage />} />
          <Route path="/legal-disclaimer" element={<LegalDisclaimerPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/reset-password" element={<ResetPassword />} /> {/* Add the reset password route */}
          
          {/* Dashboard is protected and requires authentication */}
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

// Page tracking for analytics
const PageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export default App;
