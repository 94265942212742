import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase'; // Import Firebase auth
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null); // State to track the authenticated user

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle('menu-open', !isMenuOpen);
  };

  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <div className="navbar">
      <Link to="/" className="navbar-brand">.Generate()</Link>
      <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <Link to="/" className="nav-item" onClick={() => setIsMenuOpen(false)}>RESOURCES</Link>
        <Link to="/chart" className="nav-item" onClick={() => setIsMenuOpen(false)}>LAUNCH CHART</Link>
        <Link to="/contact" className="nav-item" onClick={() => setIsMenuOpen(false)}>CONTACT</Link>
        {user ? (
          <>
            <Link to="/profile" className="nav-item" onClick={() => setIsMenuOpen(false)}>PROFILE</Link>
            <Link to="/dashboard" className="nav-item" onClick={() => setIsMenuOpen(false)}>DASHBOARD</Link>
            <Link to="/" className="nav-item" onClick={() => { setIsMenuOpen(false); handleLogout(); }}>LOG OUT</Link>
          </>
        ) : (
          <>
            <Link to="/login" className="nav-item login-button" onClick={() => setIsMenuOpen(false)}>LOG IN</Link>
            <Link to="/sign-up" className="nav-item signup-button" onClick={() => setIsMenuOpen(false)}>SIGN UP</Link>
          </>
        )}
      </div>
      <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Navbar;
