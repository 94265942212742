// ProductPage.jsx

import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import axios from 'axios';
import Papa from 'papaparse';
import './ProductPage.css';
import { HLCAreaSeries } from './hlc-area-series/hlc-area-series';
import { Link } from 'react-router-dom';
import { logEvent } from './firebase';

const ProductPage = () => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const candleSeriesRef = useRef(null);
  const smoothedMidlineSeriesRef = useRef(null);
  const customSeriesRefLightSalmon = useRef(null);
  const customSeriesRefCyan = useRef(null);
  const customSeriesRefTeal = useRef(null);
  const customSeriesRefUpper = useRef(null);
  const wsRef = useRef(null);
  const lastCandleRef = useRef(null);
  const lastCandleUpdateRef = useRef(null);
  const bandDataQueue = useRef([]);
  const [isLoading, setIsLoading] = useState(true);

  const resizeChart = () => {
    if (chartRef.current && chartContainerRef.current) {
      chartRef.current.applyOptions({
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
      });
    }
  };

  const historicalBandData = useRef({
    lightSalmon: [],
    cyan: [],
    lower: [],
    upper: []
  });

  const parseTimestamp = (timestampStr) => {
    if (!timestampStr) {
      console.error('Timestamp is missing');
      return null;
    }
    const timestamp = new Date(timestampStr.replace(' ', 'T'));
    if (isNaN(timestamp.getTime())) {
      console.error('Invalid date:', timestampStr);
      return null;
    }
    return Math.floor(timestamp.getTime() / 1000);
  };

  const createWebSocket = () => {
    wsRef.current = new WebSocket('wss://dotgenerate-backend.azurewebsites.net');

    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('WebSocket message received:', data);

      if (data.ev === 'A') {
        console.log('Processing real-time candle data:', data);

        const updatedCandle = {
          time: Math.floor(data.s / 1000),
          open: data.o,
          high: data.h,
          low: data.l,
          close: data.c,
        };

        console.log('Updated Candle Data:', updatedCandle);

        if (lastCandleRef.current && lastCandleRef.current.time) {
          const lastCandleTime = new Date(lastCandleRef.current.time * 1000);
          const updatedCandleTime = new Date(updatedCandle.time * 1000);

          if (lastCandleTime.getUTCMinutes() === updatedCandleTime.getUTCMinutes() &&
              lastCandleTime.getUTCHours() === updatedCandleTime.getUTCHours()) {
            console.log('Updating existing candle');
            lastCandleRef.current = { 
              ...lastCandleRef.current,
              high: Math.max(lastCandleRef.current.high, updatedCandle.high),
              low: Math.min(lastCandleRef.current.low, updatedCandle.low),
              close: updatedCandle.close,
            };
            if (candleSeriesRef.current) {
              console.log('Updating candle on chart:', lastCandleRef.current);
              candleSeriesRef.current.update(lastCandleRef.current);
            } else {
              console.error('candleSeriesRef.current is null when updating candle');
            }
          } else {
            console.log('Adding new candle');
            if (candleSeriesRef.current) {
              console.log('Adding new candle to chart:', updatedCandle);
              candleSeriesRef.current.update(updatedCandle);
            } else {
              console.error('candleSeriesRef.current is null when adding new candle');
            }
            lastCandleRef.current = updatedCandle;
          }
        } else {
          console.log('Adding first candle after historical data');
          lastCandleRef.current = updatedCandle;
          if (candleSeriesRef.current) {
            console.log('Adding first candle to chart:', updatedCandle);
            candleSeriesRef.current.update(updatedCandle);
          } else {
            console.error('candleSeriesRef.current is null when adding first candle');
          }
        }
        lastCandleUpdateRef.current = Date.now(); // Update the last candle update time

        // Process any pending band data updates
        processBandDataQueue();
      } else if (data.bandData) {
        console.log('Processing band data:', data.bandData);

        const { shifted_q1_up, shifted_q3_up, shifted_q1_down, shifted_q3_down, shifted_lower_bound_down, shifted_upper_bound_up, smoothed_midline, band_timestamp, midline_timestamp } = data.bandData;

        const bandTime = parseTimestamp(band_timestamp);
        const midlineTime = parseTimestamp(midline_timestamp);

        const updatedBandDataLightSalmon = {
          time: bandTime,
          high: parseFloat(shifted_q3_up),
          low: parseFloat(shifted_q1_up),
          close: (parseFloat(shifted_q3_up) + parseFloat(shifted_q1_up)) / 2,
        };

        const updatedBandDataCyan = {
          time: bandTime,
          high: parseFloat(shifted_q3_down),
          low: parseFloat(shifted_q1_down),
          close: (parseFloat(shifted_q3_down) + parseFloat(shifted_q1_down)) / 2,
        };

        const updatedBandDataTeal = {
          time: bandTime,
          high: parseFloat(shifted_q1_down),
          low: parseFloat(shifted_lower_bound_down),
          close: (parseFloat(shifted_q1_down) + parseFloat(shifted_lower_bound_down)) / 2,
        };

        const updatedBandDataUpper = {
          time: bandTime,
          high: parseFloat(shifted_upper_bound_up),
          low: parseFloat(shifted_q3_up),
          close: (parseFloat(shifted_upper_bound_up) + parseFloat(shifted_q3_up)) / 2,
        };

        const updatedMidline = {
          time: midlineTime,
          value: parseFloat(smoothed_midline)
        };

        bandDataQueue.current.push({
          updatedBandDataLightSalmon,
          updatedBandDataCyan,
          updatedBandDataTeal,
          updatedBandDataUpper,
          updatedMidline
        });

        // Process band data queue immediately if the last candle was updated recently
        processBandDataQueue();
      }
    };

    wsRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsRef.current.onclose = () => {
      console.log('Disconnected from backend WebSocket, attempting to reconnect...');
      setTimeout(() => createWebSocket(), 5000);
    };
  };

  const processBandDataQueue = () => {
    const now = Date.now();
    const lastCandleUpdateTime = lastCandleUpdateRef.current || 0;

    if (now - lastCandleUpdateTime <= 5000) { // Check if the last candle was updated within the last 5 seconds
      while (bandDataQueue.current.length > 0) {
        const bandData = bandDataQueue.current.shift();
        updateBandSeries(bandData);
      }
    } else {
      setTimeout(() => {
        if (bandDataQueue.current.length > 0) {
          const bandData = bandDataQueue.current.shift();
          updateBandSeries(bandData);
        }
      }, 5000); // Wait for 5 seconds before updating band data
    }
  };

  const updateBandSeries = (bandData) => {
    const { updatedBandDataLightSalmon, updatedBandDataCyan, updatedBandDataTeal, updatedBandDataUpper, updatedMidline } = bandData;

    if (customSeriesRefLightSalmon.current) {
      customSeriesRefLightSalmon.current.update(updatedBandDataLightSalmon);
    } else {
      console.error('customSeriesRefLightSalmon.current is null');
    }

    if (customSeriesRefCyan.current) {
      customSeriesRefCyan.current.update(updatedBandDataCyan);
    } else {
      console.error('customSeriesRefCyan.current is null');
    }

    if (customSeriesRefTeal.current) {
      customSeriesRefTeal.current.update(updatedBandDataTeal);
    } else {
      console.error('customSeriesRefTeal.current is null');
    }

    if (customSeriesRefUpper.current) {
      customSeriesRefUpper.current.update(updatedBandDataUpper);
    } else {
      console.error('customSeriesRefUpper.current is null');
    }

    if (smoothedMidlineSeriesRef.current) {
      smoothedMidlineSeriesRef.current.update(updatedMidline);
    } else {
      console.error('smoothedMidlineSeriesRef.current is null');
    }
  };

  const retryFetch = async (fetchFunction, delay = 8000, maxAttempts = 40) => {
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        await fetchFunction();
        return;
      } catch (error) {
        console.error(`Fetch attempt ${attempt} failed:`, error);
        if (attempt === maxAttempts) {
          console.error('Max fetch attempts reached.');
          throw error;
        }
        await new Promise(res => setTimeout(res, delay));
      }
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      try {
        // Ensure fetchOHLCData runs first
        await retryFetch(fetchOHLCData);
        // Then fetch band data and smoothed midline concurrently
        await Promise.all([
          retryFetch(fetchBandData),
          retryFetch(fetchSmoothedMidlineData)
        ]);
        // Now that all data is fetched, you can set isLoading to false if needed
        setIsLoading(false);
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    };

    initializeData();
    createWebSocket();

    logEvent('page_view', { page_path: 'ProductPage' }); // Log page view when the component is mounted

    window.addEventListener('resize', resizeChart);

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
      window.removeEventListener('resize', resizeChart); // Cleanup the event listener
    };
  }, []);

  const fetchOHLCData = async () => {
    try {
      const response = await axios.get('https://dotgenerate-backend.azurewebsites.net/ohlc');
      const parsedData = Papa.parse(response.data, { header: true });

      const candleSeriesData = parsedData.data.slice(-30000).map(row => {
        const time = parseTimestamp(row.timestamp);
        if (!time) return null;
        return {
          time,
          open: parseFloat(row.open),
          high: parseFloat(row.high),
          low: parseFloat(row.low),
          close: parseFloat(row.close),
        };
      }).filter(item => item !== null);

      const uniqueCandleSeriesData = Array.from(new Map(candleSeriesData.map(item => [item.time, item])).values());
      uniqueCandleSeriesData.sort((a, b) => a.time - b.time);

      if (chartContainerRef.current) {
        if (chartRef.current) {
          chartRef.current.remove();
        }

        chartRef.current = createChart(chartContainerRef.current, {
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
          layout: {
            background: {
              color: '#e0e0e0', // Teenage Engineering grey
            },
            textColor: '#222222',
          },
          grid: {
            vertLines: {
              color: '#bbbbbb',
            },
            horzLines: {
              color: '#bbbbbb',
            },
          },
          timeScale: {
            timeVisible: true,
            secondsVisible: false,
            tickLabelFontSize: 20,
            tickLabelColor: '#222222',
          },
          priceScale: {
            tickLabelFontSize: 20,
            tickLabelColor: '#222222',
          }
        });

        candleSeriesRef.current = chartRef.current.addCandlestickSeries({
          upColor: '#39FF14', // Neon Green
          downColor: '#FF073A', // Neon Red
          borderUpColor: '#000000', // Black border for both up and down
          borderDownColor: '#000000', // Black border for both up and down
          wickUpColor: '#000000', // Black wick for both up and down
          wickDownColor: '#000000' // Black wick for both up and down
        });

        candleSeriesRef.current.setData(uniqueCandleSeriesData);
        lastCandleRef.current = uniqueCandleSeriesData[uniqueCandleSeriesData.length - 1];
      }
    } catch (error) {
      console.error('Error fetching OHLC data:', error);
      throw error;
    }
  };

  const fetchBandData = async () => {
    try {
      const response = await axios.get('https://dotgenerate-backend.azurewebsites.net/bands');
      const parsedData = Papa.parse(response.data, { header: true });

      const bandData = parsedData.data.slice(-30000);

      const bandDataLightSalmon = bandData.map(row => {
        const time = parseTimestamp(row.timestamp);
        if (!time || row.shifted_q1_up === "None" || row.shifted_q3_up === "None") return null;
        return {
          time,
          high: parseFloat(row.shifted_q3_up),
          low: parseFloat(row.shifted_q1_up),
          close: (parseFloat(row.shifted_q3_up) + parseFloat(row.shifted_q1_up)) / 2,
        };
      }).filter(item => item !== null);

      historicalBandData.current.lightSalmon = Array.from(new Map(bandDataLightSalmon.map(item => [item.time, item])).values());
      historicalBandData.current.lightSalmon.sort((a, b) => a.time - b.time);

      const bandDataCyan = bandData.map(row => {
        const time = parseTimestamp(row.timestamp);
        if (!time || row.shifted_q1_down === "None" || row.shifted_q3_down === "None") return null;
        return {
          time,
          high: parseFloat(row.shifted_q3_down),
          low: parseFloat(row.shifted_q1_down),
          close: (parseFloat(row.shifted_q3_down) + parseFloat(row.shifted_q1_down)) / 2,
        };
      }).filter(item => item !== null);

      historicalBandData.current.cyan = Array.from(new Map(bandDataCyan.map(item => [item.time, item])).values());
      historicalBandData.current.cyan.sort((a, b) => a.time - b.time);

      const bandDataTeal = bandData.map(row => {
        const time = parseTimestamp(row.timestamp);
        if (!time || row.shifted_lower_bound_down === "None" || row.shifted_q1_down === "None") return null;
        return {
          time,
          high: parseFloat(row.shifted_q1_down),
          low: parseFloat(row.shifted_lower_bound_down),
          close: (parseFloat(row.shifted_q1_down) + parseFloat(row.shifted_lower_bound_down)) / 2,
        };
      }).filter(item => item !== null);

      historicalBandData.current.lower = Array.from(new Map(bandDataTeal.map(item => [item.time, item])).values());
      historicalBandData.current.lower.sort((a, b) => a.time - b.time);

      const bandDataUpper = bandData.map(row => {
        const time = parseTimestamp(row.timestamp);
        if (!time || row.shifted_upper_bound_up === "None" || row.shifted_q3_up === "None") return null;
        return {
          time,
          high: parseFloat(row.shifted_upper_bound_up),
          low: parseFloat(row.shifted_q3_up),
          close: (parseFloat(row.shifted_upper_bound_up) + parseFloat(row.shifted_q3_up)) / 2,
        };
      }).filter(item => item !== null);

      historicalBandData.current.upper = Array.from(new Map(bandDataUpper.map(item => [item.time, item])).values());
      historicalBandData.current.upper.sort((a, b) => a.time - b.time);

      if (chartRef.current) {
        const customSeriesViewLightSalmon = new HLCAreaSeries();
        customSeriesRefLightSalmon.current = chartRef.current.addCustomSeries(customSeriesViewLightSalmon, {
          highLineColor: '#FFA07A', // Neon Light Salmon
          lowLineColor: '#FFA07A', // Neon Light Salmon
          closeLineColor: '#FFA07A', // Neon Light Salmon
          areaBottomColor: 'rgba(255, 160, 122, 0.5)', // More vibrant Neon Light Salmon
          areaTopColor: 'rgba(255, 160, 122, 0.5)', // More vibrant Neon Light Salmon
          highLineWidth: 2,
          lowLineWidth: 2,
          closeLineWidth: 2,
        });

        customSeriesRefLightSalmon.current.setData(historicalBandData.current.lightSalmon);

        const customSeriesViewCyan = new HLCAreaSeries();
        customSeriesRefCyan.current = chartRef.current.addCustomSeries(customSeriesViewCyan, {
          highLineColor: '#A9A9A9', // Dark gray for time series
          lowLineColor: '#A9A9A9', // Dark gray for time series
          closeLineColor: '#A9A9A9', // Dark gray for time series
          areaBottomColor: 'rgba(0, 240, 255, 0.5)', // More vibrant Neon Cyan
          areaTopColor: 'rgba(0, 240, 255, 0.5)', // More vibrant Neon Cyan
          highLineWidth: 2,
          lowLineWidth: 2,
          closeLineWidth: 2,
        });

        customSeriesRefCyan.current.setData(historicalBandData.current.cyan);

        const customSeriesViewTeal = new HLCAreaSeries();
        customSeriesRefTeal.current = chartRef.current.addCustomSeries(customSeriesViewTeal, {
          highLineColor: '#A9A9A9', // Dark gray for time series
          lowLineColor: '#A9A9A9', // Dark gray for time series
          closeLineColor: '#A9A9A9', // Dark gray for time series
          areaBottomColor: 'rgba(0, 255, 204, 0.5)', // More vibrant Neon Teal
          areaTopColor: 'rgba(0, 255, 204, 0.5)', // More vibrant Neon Teal
          highLineWidth: 2,
          lowLineWidth: 2,
          closeLineWidth: 2,
        });

        customSeriesRefTeal.current.setData(historicalBandData.current.lower);

        const customSeriesViewUpper = new HLCAreaSeries();
        customSeriesRefUpper.current = chartRef.current.addCustomSeries(customSeriesViewUpper, {
          highLineColor: '#FF4500', // Neon OrangeRed
          lowLineColor: '#FF4500', // Neon OrangeRed
          closeLineColor: 'rgba(255, 69, 0, 0.5)', // More vibrant Neon OrangeRed
          areaBottomColor: 'rgba(255, 69, 0, 0.5)', // More vibrant Neon OrangeRed
          areaTopColor: 'rgba(255, 69, 0, 0.5)', // More vibrant Neon OrangeRed
          highLineWidth: 2,
          lowLineWidth: 2,
          closeLineWidth: 2,
        });

        customSeriesRefUpper.current.setData(historicalBandData.current.upper);
      }
    } catch (error) {
      console.error('Error fetching band data:', error);
      throw error;
    }
  };

  const fetchSmoothedMidlineData = async () => {
    try {
      const response = await axios.get('https://dotgenerate-backend.azurewebsites.net/ohlc');
      const parsedData = Papa.parse(response.data, { header: true });

      const smoothedMidlineData = parsedData.data.slice(-30000).map(row => {
        const time = parseTimestamp(row.timestamp);
        if (!time || !row.smoothed_midline) return null;
        return {
          time,
          value: parseFloat(row.smoothed_midline),
        };
      }).filter(item => item !== null);

      if (chartRef.current) {
        smoothedMidlineSeriesRef.current = chartRef.current.addLineSeries({
          color: '#0011ff', // Darkish gray for the smoothed midline
          lineWidth: 2,
        });

        smoothedMidlineSeriesRef.current.setData(smoothedMidlineData);
      }
    } catch (error) {
      console.error('Error fetching smoothed midline data:', error);
      throw error;
    }
  };

  const showTermsOfUse = () => {
    alert("Terms of Use:\n\n[Add your terms of use text here.]");
  };

  return (
    <>
      <div className="top-bar">
        <div className="trading-pair">
          <h2>Trading Pair: BTC-USD</h2>
        </div>
        <Link to="/" className="nav-item-chart" onClick={() => logEvent('navigation', { destination: 'home' })}>Home</Link>
      </div>
      <div className="chart-container" ref={chartContainerRef}></div>
      <div className="disclaimer">
        <div className="left-text">
          <p>.Generate()</p>
        </div>
        <div className="centered-text">
          <p className='disclaimer-text'>The orange and blue bands are AI generated and can vary in accuracy. All content on this site is for educational purposes only and does not constitute financial advice. <Link to="/legal-disclaimer" className="disclaimer-link" onClick={() => logEvent('navigation', { destination: 'legal_disclaimer' })}>Legal Disclaimer</Link></p>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
