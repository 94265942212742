import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const [nickname, setNickname] = useState('');
  const [newNickname, setNewNickname] = useState('');
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setNickname(userDoc.data().nickname);
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleUpdateNickname = async (e) => {
    e.preventDefault();
    try {
      if (user) {
        await updateDoc(doc(db, 'users', user.uid), {
          nickname: newNickname,
        });
        setNickname(newNickname);
        setNewNickname('');
        setMessage('Nickname updated successfully.');
        setError('');
      }
    } catch (error) {
      setError(error.message);
      setMessage('');
    }
  };

  return (
    <div className="dashboard-page">
      <div className="navbar">
        <div className="navbar-brand">.Generate()</div>
        <div className="nav-links">
          <Link to="/" className="nav-item">RESOURCES</Link>
          <Link to="/login" className="nav-item">LOG IN</Link>
          <Link to="/sign-up" className="nav-item">SIGN UP</Link>
          <Link to="/contact" className="nav-item">CONTACT</Link>
        </div>
      </div>

      <div className="dashboard-container">
        <div className="dashboard-box">
          <h2>Dashboard</h2>
          {user ? (
            <div>
              <p>Welcome, {nickname}</p>
              <form onSubmit={handleUpdateNickname}>
                <input
                  type="text"
                  value={newNickname}
                  onChange={(e) => setNewNickname(e.target.value)}
                  placeholder="New Nickname"
                  required
                />
                <button type="submit">Update Nickname</button>
              </form>
              {message && <p className="message">{message}</p>}
              {error && <p className="error">{error}</p>}
            </div>
          ) : (
            <p>Please log in to view your dashboard.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
