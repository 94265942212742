// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCsrjGnKKaXNnV545Hvmz9_vkPc5DDKwUo",
    authDomain: "dotgenerate.firebaseapp.com",
    projectId: "dotgenerate",
    storageBucket: "dotgenerate.appspot.com",
    messagingSenderId: "1072520720281",
    appId: "1:1072520720281:web:9d65a98a43d086d544ed08",
    measurementId: "G-W55JEK8LEJ" // Ensure this matches your Firebase Analytics measurement ID
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

// Optional: Utility function to log events using Firebase Analytics
export const logEvent = (eventName, eventParams) => {
    firebaseLogEvent(analytics, eventName, eventParams);
};
